import React from 'react';
import office from '../../../../assets/images/3.jpg';

export default function TheCenter() {
  return (
    <section className="the-center">
      <div className="container">
        <div className="flex">
          <div className="the-center-photo">
            <img src={office} alt='Office Space' />
          </div>
          <div className="the-center-text">
            <h3>The Centre</h3>
            <p>In November 2009, the Centre moved from its rented quarters to its newly constructed offices on its 4.5 hectare permanent site at Zangon Shanu, along Sokoto Road, about a kilometer from the Ahmadu Bello Campus. Then President Shehu ‘ Yar Adua laid the foundation stone for the new building on 31st March, 2008, on the occasion of the Second Yusufu Bala Usman Memorial Lecture. However, the present offices and library have proved too small for our needs, and plans for expansion are on-going. It is expected that the new extension will accommodate 10 offices, as well as enlarged library space, including the newspaper library.</p>
          </div>
        </div>
      </div>
    </section>
  )
}
