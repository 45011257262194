import React from 'react';
import { Link } from 'react-router-dom';
import image from '../../../../../src/assets/images/4.jpg'

export default function CurrentProject() {
  return (
    <section className='current-project'>
      <div className="container">
        <div className="card">
          <div className="card-text">
            <h3>Current Project</h3>
            <p>Understanding the Almajiri Phenomenon in Nothern Nigeria</p>
            <Link className='button white' to='/contact'>Contact us</Link>
          </div>
          <div className="card-image">
          <img src={image} alt="" />
          </div>
        </div>
      </div>
    </section>
  )
}
