import React from 'react';
import logo from '../../assets/images/logo.png';
import NavigationItems from '../navigation';
import { Link } from 'react-router-dom';
import { TiSocialFacebook, TiSocialTwitter, TiSocialLinkedin } from 'react-icons/ti';
import { FaInstagram } from 'react-icons/fa';

export default function Header(props) {
  return (
    <header className='header'>
      <div className="top-header">
        <div className="container">
          <div className="flex">
            <div className="social-links">
              <a href='https://facebook.com'>
                <TiSocialFacebook size={20} />
              </a>
              <a href='https://facebook.com'>
                <TiSocialTwitter size={20} />
              </a>
              <a href='https://facebook.com'>
                <TiSocialLinkedin size={20} />
              </a>
              <a href='https://facebook.com'>
                <FaInstagram size={20} />
              </a>
            </div>
            <div className="tel">
              <a href='tel:+2348037023317'>Tel: (234) 803 702 3317</a>
            </div>
          </div>
        </div>
      </div>
      <div className="container">
        <div className="flex">
          <Link to='/'>
            <div className="logo">
              <img src={logo} alt='Ceddert' />
            </div>
          </Link>
          <nav className='main-nav'>
            <NavigationItems />
          </nav>
          <div className={`hamburger hamburger--elastic  ${props.showMenu && 'is-active'}`}>
            <div className="hamburger-box" onClick={props.clicked}>
              <div className="hamburger-inner"></div>
            </div>
          </div>
        </div>
        <div className={`mobile-menu ${props.showMenu && 'show'}`}>
          <nav className='mobile-nav'>
            <NavigationItems type='mobile' />
          </nav>
        </div>
      </div>
    </header>
  )
}
