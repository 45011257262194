import React from 'react'

export default function Capabilities() {
  return (
    <section className='capabilities'>
      <div className="container">
        <h3 className="title">Our Capabilities</h3>
        <div className="grid">
          <div className="grid-item" id='research'>
            <h4>Research</h4>
            <p>To conduct research into policies and
              programmes that affect the lives and living
              standards or people In Nigeria using both
              qualitative and quantitative methods.</p>
          </div>
          <div className="grid-item" id='democratic'>
            <h4>Democratic Empowerment</h4>
            <p>To enhance popular participation in
              governance ov inana ways or empowerna
              people to participate Tully In the democratic process.</p>
          </div>
          <div className="grid-item" id='societal'>
          <h4>Societal Conflict Resolutior</h4>
            <p>To enhance popular participation in governance by finding ways of empowering
people to participate fully in the democratic process.</p>
          </div>
          <div className="grid-item" id='trainings'>
            <h4>Trainings</h4>
            <p>To carry out training for officials at all
devels or govemment to mrove her ee
OT expertise and enhance service delivery.</p>
          </div>
        </div>
      </div>
    </section>
  )
}
