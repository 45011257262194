import React from 'react';
import { ReactSVG } from 'react-svg';
import libraryPhoto from '../../assets/images/library.jpg';
import psycology from '../../assets/images/psychology.svg';
import stage from '../../assets/images/stage.svg';
import ladder from '../../assets/images/4079577_development_ladder_person_personal_steps_icon.svg';
import endorser from '../../assets/images/endorser.jpg';

export default function Library() {
  return (
    <section className='library'>

      <div className="section-header">
        <div className="container">
          <h1>Our Library</h1>
        </div>
      </div>

      <div className="top-section">
        <div className="container">
          <div className="flex">
            <div className="library-photo">
              <img src={libraryPhoto} alt='Library' />
            </div>
            <div className="library-info">
              <h5>CEDDERT Library has about 25,000 books and other documents.</h5>
              <ul>
                <li>
                  <div className='icon' id='psycology'>
                    <ReactSVG src={psycology} alt='Psycology' />
                  </div>
                  Personal Research
                </li>
                <li>
                  <div className='icon' id='ladder'>
                    <ReactSVG src={ladder} alt='Ladder' />
                  </div>
                  Education Research
                </li>
                <li>
                  <div className='icon' id='stage'>
                    <ReactSVG src={stage} alt='Stage' />
                  </div>
                  Seminar
                </li>
              </ul>
            </div>
          </div>
          <p className='library-description'>At present, the CEDDERT library houses a collection of about 25,000 books, including major donations from the British Council (over 3000 volumes); Ambassador Isa Modibbo, MD Yusufu (about 4000); and staff of CEDDERT, Alkasum Abba (3000), Norma Perchonock (2500); Saidu Adamu (750). The Library is over-seen by our indefatigable Librarian, Garba Wada. The present library facilities are inadequate and it is expected that they will be substantially expanded when the new extension is completed.</p>
        </div>
      </div>

      <div className="library-sections">
        <div className="container">
          <div className="section-header">
            <h1>Our Library Sections</h1>
          </div>
          <div className="sections">
            <div className="section">
              <h3>Newspaper Library</h3>
              <p>The library has a complete sets of Nigerian newspapers from 1986 to date plus many issues from before then</p>
            </div>
            <div className="section">
              <h3>Magazine Collection</h3>
              <p>The library is equipped with a large magazine collection, including complete sets of The Analyst, Analysis and Fitila.</p>
            </div>
            <div className="section">
              <h3>Books</h3>
              <p>The library is also packed with varieties of books</p>
            </div>
            <div className="section">
              <h3>Other Resources</h3>
              <p>In addition, the Library contains numerous documents, reports, gazettes, and other important papers</p>
            </div>
          </div>
        </div>
      </div>

      <div className="library-testimonials">
        <div className="container">
          <div className="flex">
            <div className="endorser-photo">
              <img src={endorser} alt='Endorser' />
            </div>
            <div className="testimonial">
              <h3>M.D. Yusuf</h3>
              <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Qui similique quaerat rerum enim laudantium dicta, ipsam eaque quidem repellendus itaque possimus officiis voluptas in aperiam aliquid repudiandae animi temporibus expedita?</p>
            </div>
          </div>
        </div>
      </div>

    </section>
  )
}