import React from 'react';
import logo from '../../assets/images/logo.png';
import { Link } from 'react-router-dom';
import { TiSocialFacebook, TiSocialTwitter, TiSocialLinkedin } from 'react-icons/ti';
import { FaInstagram } from 'react-icons/fa';
import { BsArrowUp } from 'react-icons/bs';

export default function Footer() {
  const year = new Date();
  return (
    <footer>
      <div className="container">
        <div className="top-footer">
          <div className="logo">
            <img src={logo} alt='CEDDET' />
          </div>
          <ul className='footer-links'>
            <li>
              <Link to='/about'>About Us</Link>
            </li>
            <li>
              <Link to='/blog'>Blog</Link>
            </li>
            <li>
              <Link to='/library'>Library</Link>
            </li>
            <li>
              <Link to='/contact'>Contact Us</Link>
            </li>
          </ul>
        </div>
        <div className="bottom-footer">
          <div className="copyright">
            <p>&copy; {year.getFullYear()} CEDDET. All Rights Reserved.</p>
          </div>

          <ul className='social-links'>
            <li>
              <a href='https://facebook.com'>
                <TiSocialFacebook size={24} />
              </a>
            </li>
            <li>
              <a href='https://twitter.com'>
                <TiSocialTwitter size={24} />
              </a>
            </li>
            <li>
              <a href='https://instagram.com'>
                <FaInstagram size={24} />
              </a>
            </li>
            <li>
              <a href='https://linkedin.com'>
                <TiSocialLinkedin size={24} />
              </a>
            </li>
          </ul>

        </div>

      </div>

      <div className='go-up'>
        <BsArrowUp size={20} />
      </div>
    </footer>
  )
}
