import React from 'react';
import BlogPosts from '../../components/pages/blog';

export default function Blog() {
  return (
    <section>
      <div className='container'>
        <BlogPosts title="Blog Posts" />
      </div>
    </section>
  )
}