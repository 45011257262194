import Layout from './layout';
import Routes from './routes';
import './scss/main.scss';

function App() {
  return (
    <Layout>
      <title>CEDDERT</title>
      <Routes />
    </Layout>
  );
}

export default App;
