import React from 'react';
import { ReactSVG } from 'react-svg';
import conference from '../../../../assets/images/video-conference.svg';
import workshop from '../../../../assets/images/conference-education.svg';
import video from '../../../../assets/images/group-discussion.svg';

export default function Features() {
  return (
    <section className='features'>
      <div className="container">
        <div className="features-row">
          <div className="features-col" id='conference'>
            <ReactSVG fontSize={34} className='svg' src={conference} />
            <div className='description'>
              <h3>Conferences</h3>
              <p>Organized to facilitate the dissemination and application of knowledge-based solutions and
                initiatives on issues faced by the Nigerian economy.</p>
            </div>
          </div>
          <div className="features-col" id='workshop'>
            <ReactSVG className='svg' src={workshop} />
            <div className='description'>
              <h3>Workshops</h3>
              <p>Offering unique opportunities to learn from diverse professionals which cuts across various
                fields of study.</p>
            </div>
          </div>
          <div className="features-col" id='meeting'>
            <ReactSVG className='svg' src={video} />
            <div className="description">
              <h3>Meetings</h3>
              <p>Periodic stakeholder forums for evaluating projects, researches and other organizational
                developments implemented per time.</p>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}
