import React from 'react';
import { Routes, Route } from 'react-router-dom';

// Pages
import Home from '../pages/Home';
import About from '../pages/About';
import Blog from '../pages/Blog';
import Contact from '../pages/Contact';
import Library from '../pages/Library';

const routes = () => {

  return (
    <Routes>
      <Route element={<Home />} path="/" />
      <Route element={<About />} path="about" />
      <Route element={<Blog />} path="blog" />
      <Route element={<Library />} path="library" />
      <Route element={<Contact />} path="contact" />
    </Routes>
  );
}

export default routes;