import React from "react";
import NavigationItem from "../navigationItem";

let headerNavList = null;

export const navigationItems = ({ type }) => {
  headerNavList =
    <ul className={type === 'mobile' && 'mobile-nav-list'}>
      <NavigationItem link="/">Home</NavigationItem>
      <NavigationItem link="/about">About Us</NavigationItem>
      <NavigationItem link="/blog">Blog</NavigationItem>
      <NavigationItem link="/library">Library</NavigationItem>
      <NavigationItem link="contact">Contact Us</NavigationItem>
    </ul>
  return headerNavList;
}