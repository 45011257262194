import React from 'react';
import { NavLink } from "react-router-dom";

export default function NavigationItem(props) {

  let listItem = <li>
    <NavLink
      to={props.link}
      activeclass="active">
      {props.children}
    </NavLink>

  </li>;

  return listItem;

}