import React from 'react';
import bigHeroImg from '../../../../assets/images/1.jpg';
import {Link} from 'react-router-dom';

export default function BigHero() {
  return (
    <section className='big-hero'>
      <div className="container">
        <div className='flex'>
          <div className="big-hero-info">
            <h2>Centre For Democratic <br /> Development, Research <br />and Training <span>(CEDDET)</span></h2>
            <div className="cta-buttons">
              <Link className='button primary' to='about'>About Us</Link>
              <Link className='button outline' to='blog'>Our Activities</Link>
            </div>
          </div>
          <div className="big-hero-photo">
            <img src={bigHeroImg} alt='Big Hero' />
          </div>
        </div>
      </div>
    </section>
  )
}
