import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Header from './header';
import Footer from './footer';

export default function Layout(props) {
  const [openNavMenu, setNavMenu] = useState(false);
  const [screenWidth, setScreenWidth] = useState(0);
  const body = document.getElementsByTagName('body')[0];

  const navigate = useNavigate();

  const screenWidthHandler = () => {
    let width = window.screen.width;
    setScreenWidth(width);
  }

  useEffect(() => {
    screenWidthHandler();
    navigate(1);
    setNavMenu(false);
    body.style.overflow = 'scroll';

  }, [navigate, setNavMenu, body.style])

  const navMenuHandler = () => {
    
    setNavMenu(!openNavMenu);
    if(openNavMenu) {
      body.style.overflow = 'scroll';
    } else {
      body.style.overflow = 'hidden';
    }
  };

  return (
    <React.Fragment>
      <Header
        showMenu={openNavMenu}
        clicked={navMenuHandler}
        screenWidth={screenWidth} />
      <main>
        {props.children}
      </main>
      <Footer />
    </React.Fragment>
  )
}
