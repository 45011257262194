import React from 'react';
import { Link } from 'react-router-dom';
import image01 from '../../../assets/images/01.jpeg';
import image02 from '../../../assets/images/02.jpeg';
import image03 from '../../../assets/images/03.jpeg';



export default function BlogPosts({ title }) {

  return (
    <section className='blog'>
      <div className="container">
        <h3 className="title">{title}</h3>
        <div className="grid">
          <div className="grid-item">
            <Link to='/'>
              <div className="image">
                <img src={image01} alt="Blog" />
              </div>
              <div className="text">
                <p>Training Workshop On Deepening Community Participation In Advocacy And Monitoring Of SUBEB Projects</p>
              </div>
            </Link>
          </div>
          <div className="grid-item">
            <Link to='/'>
              <div className="image">
                <img src={image02} alt="Blog" />
              </div>
              <div className="text">
                <p>Monitoring of State Universal Basic Education Board (SUBEB) Projects in Sabon Gari, Giwa and Kagarko Local Government Areas – Supported by MacArthur Foundation</p>
              </div>
            </Link>
          </div>
          <div className="grid-item">
            <Link to='/'>
              <div className="image">
                <img src={image03} alt="Blog" />
              </div>
              <div className="text">
                <p>Research on Rural Banditry in Kaduna, Zamfara and Katsina States</p>
              </div>
            </Link>
          </div>
        </div>
      </div>
    </section>
  )
}
