import React from 'react';
import image1 from '../../assets/images/1.jpg';
import image2 from '../../assets/images/2.jpg';
import image3 from '../../assets/images/3.jpg';
import image4 from '../../assets/images/4.jpg';
import endorser from '../../assets/images/endorser.jpg';


export default function About() {
  return (
    <section className='about'>
      <div className='container'>
        <h1 className='section-header'>About Us</h1>

        <div className="images">
          <div className='image-container'>
            <img src={image1} alt='1' />
          </div>
          <div className='image-container' id='image-2'>
            <img src={image2} alt='2' />
          </div>
          <div className='image-container' id='image-3'>
            <img src={image3} alt='3' />
          </div>
          <div className='image-container' id='image-4'>
            <img src={image4} alt='4' />
          </div>
        </div>
      </div>

      <div className='about-text'>
        <div className="container">
          <h1 className='title'>Our Story</h1>
          <p className='description'>CEDDERT was incorporated by a group of Northern Nigerian intellectuals in March 1992 as an independent, non-profit scholarly trust with the aim of promoting and advancing scientific research and training in order to provide solutions to the problems of democratic development at the community, national and pan-African level. Having been involved in both scholarly work as well as political action, the founders believed that only sound empirical research could provide a firm basis for effective political activity and solutions to the problems of empowering people and deepening democracy.</p>
        </div>
      </div>

      <div className='governing-structure'>
        <div className="container">
          <div className="grid">
            <div className="image">
              <img src={image1} alt="" />
            </div>
            <div className="message">
              <h1 className='title'>CEDDERT Governing Structure and Operations</h1>
              <p className='description'>CEDDERT is today governed by a Board of Trustees which sets policy; a Director; and a Management Committee, members of which are heads of departments. The committee coordinates a network of researchers drawn from universities, institutes, research centres, private consultancies and other organisations inside and outside Nigeria. The method of operation involves a synergy between its own researchers (most of whom work on a voluntary basis, being paid no salaries) and others coming from outside for the purpose of specific research projects. CEDDERT normally provides library facilities, coordination and logistics, secretarial and other support services once funding has been sourced for projects. CEDDERT also undertakes to publish research findings.</p>
            </div>
            <div className="abstract"></div>

          </div>
        </div>
      </div>
      <div className="objectives">
        <div className="container">
          <h1 className='title'>Objectives</h1>
          <p>The main objective of CEDDERT is to conduct problem-oriented and policy-related scientific research on the processes of community and national development in order to build self-reliance and the ability of people to promote and defend their unity, security, democratic rights and human dignity. To this end, the Centre regularly seeks to identify, collect and preserve comprehensive, accurate and relevant data and other research material on all aspects of community and national development for the use of its staff, trainees, associates and collaborating researchers. All materials are housed in the Centre’s library, containing documents, other primary resources, books and an extensive newspaper library.</p>
          <strong>Over the past two decades the Centre has published a wide variety of research bulletins, pamphlets, books, magazine etc for the purpose of disseminating the results of its research activities, as well as public lectures, workshops, seminars and conferences it has organized on current issues.</strong>

          <div className="grid">

            <div className="column">
              <div className='inner'>
                <h1>Library Resources</h1>
                <p>At present, the CEDDERT library houses a collection of about 25,000 books, including major donations from the British Council (over 3000 volumes); Ambassador Isa Modibbo, Yusufu Bala Usman (over 5000); MD Yusufu (about 4000); and staff of CEDDERT, Alkasum Abba (3000), Norma Perchonock (2500); Saidu Adamu (750).</p>
              </div>
            </div>

            <div className="column">
              <div className='inner'>
                <h1>Others</h1>
                <p>The Library contains numerous documents, reports, gazettes, and other important papers. The Newspaper Library has complete sets of Nigerian newspapers from 1986 to date. There is also a large magazine collection, including complete sets of The Analyst, Analysis and Fitila.   The Library is over-seen by our indefatigable Librarian, Garba Wada.</p>
              </div>
            </div>
          </div>

        </div>
      </div>

      <div className="board-of-trustees">
        <div className="container">
          <h1 className='title'>Board of Trustees</h1>
          <div className="grid">
            <div className="column">
              <div className="trustee-photo">
                <img src={endorser} alt="Endorser" />
              </div>
              <h3 className="trustee-name">Muhammadu Mustapha Gwadabe</h3>
              <p className="trustee-position">Head of ICT</p>
              <p className="view-details">View details &#8594;</p>
            </div>
            <div className="column">
              <div className="trustee-photo">
                <img src={endorser} alt="Endorser" />
              </div>
              <h3 className="trustee-name">Abdulkadir Adamu</h3>
              <p className="trustee-position">Head of Adminstration and Library Services</p>
              <p className="view-details">View details &#8594;</p>
            </div>
            <div className="column">
              <div className="trustee-photo">
                <img src={endorser} alt="Endorser" />
              </div>
              <h3 className="trustee-name">Massoud Umar</h3>
              <p className="trustee-position">Head of Research</p>
              <p className="view-details">View details &#8594;</p>
            </div>
          </div>
        </div>
      </div>

    </section>
  )
}