import React, { useEffect, useState } from 'react';
import BigHero from '../../components/pages/home/bigHero';
import Features from '../../components/pages/home/features';
import TheCenter from '../../components/pages/home/theCenter';
import Capabilities from '../../components/pages/home/capabilities';
import CurrentProject from '../../components/pages/home/currentProject';
import RecentBlogs from '../../components/pages/blog';
import axios from 'axios';


export default function Home() {
  // State
  const [posts, setPosts] = useState([])
  useEffect(() => {
    axios.get('http://localhost:3000/posts')
      .then(response => setPosts(response.data))
    // fetch('http://localhost:3000/posts'
    //   , {
    //     headers: {
    //       'Content-Type': 'application/json',
    //       'Accept': 'application/json'
    //     }
    //   }
    // )
    //   .then(function (response) {
    //     return response.json();
    //   })
    //   .then(function (myJson) {
    //     console.log(myJson);
    //   }).catch(error => console.log(error));
  }, []);
  return (
    <React.Fragment>
      <BigHero />
      <Features />
      <TheCenter />
      <Capabilities />
      <CurrentProject />
      <RecentBlogs posts={posts} title='Recent Activities' />
    </React.Fragment>
  )
}
